@font-face {
  font-family: '5yearsoldfont';
  src: local('5yearsoldfont'),
    url('./assets//fonts/5yearsoldfont/5yearsoldfont.ttf') format('truetype');
}
body {
  font-family: '5yearsoldfont', cursive;
  font-weight: 400;
  font-style: normal;
  background-color: #0149ab;
}
.css-1ax51xe-MuiGrid-root {
  background-color: inherit !important;
}
.cus-content {
  justify-content: flex-end;
}
